import { Store } from "@mui/icons-material";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { SupportRequestType } from "./types";

export const QUERY_KEY_FETCH_PRODUCTS = "fetch-stale-products";

export const reasonList: { label: string; value: SupportRequestType }[] = [
  { label: "Request a new Vendor", value: "vendor-request" },
  { label: "Request an integration", value: "integration-request" },
  { label: "Request a new deckbox addition", value: "deckbox-request" },
  { label: "Feature request", value: "feature-request" },
  { label: "General feedback", value: "general-feedback" },
  { label: "Report an issue", value: "bug-report" },
];

export const importSourceList: { label: string; value: string; }[] = [
    { label: "Archidekt CSV File (.csv)", value: "archidekt_csv" },
    { label: "Archidekt Text File (.txt)", value: "archidekt_txt" },
    { label: "Deckbox CSV File (.csv)", value: "deckbox_csv" },
    { label: "Manabox Text File (.txt)", value: "manabox_txt" },
    { label: "Moxfield Text File (.txt)", value: "moxfield_txt" },
    { label: "TopDecked CSV File (.csv)", value: "topdecked_csv" },
];
