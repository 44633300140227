import Cookies from "js-cookie";
import { ApiResponse, CardType, ProductType, VendorType } from "../common/types";
import { makeRequest } from "./helpers";

export const fetchRetailers = async () => {
  const response = await makeRequest<ApiResponse<VendorType[]>>({
    url: "/retailer",
  });

  return response.data;
};

export const fetchProducts = async (
  cardId: number,
  retailers: string[],
  isPolling = false,
) => {
  const retailerQuery = "retailers[]=" + retailers.join("&retailers[]=");
  const pollParam = isPolling ? "1" : "0";

  const response = await makeRequest<{
    products: ProductType[];
    card: CardType;
  }>({
    url: `/card/${cardId}/products?${retailerQuery}&poll=${pollParam}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  });

  return response;
};

export const registerUser = async (values: {
  email: string;
  password: string;
}) => {
  const response = await makeRequest({
    url: "/register",
    body: JSON.stringify(values),
  });

  return response;
};

export const loginUser = async (values: {
  email: string;
  password: string;
}) => {
  const response = await makeRequest({
    url: "/login",
    body: JSON.stringify(values),
  });

  return response;
};

export const forgotPassword = async (email: string) => {
  const response = await makeRequest({
    url: "/forgot-password",
    body: JSON.stringify({ email: email }),
  });

  return response;
};

export const createNewPassword = async (
  token: string,
  password: string,
  password_confirmation: string,
  email: string,
) => {
  const response = await makeRequest({
    url: "/reset-password",
    body: JSON.stringify({
      token: token,
      password: password,
      password_confirmation: password_confirmation,
      email: email,
    }),
  });

  return response;
};

export const fetchCardsWithSearch = async (searchTerm: string) => {
  const response = await makeRequest<ApiResponse<CardType[]>>({
    url: `/card/search?name=${encodeURIComponent(searchTerm)}`,
    method: "GET",
  });

  return response.cards;
};

export const fetchCardsForWishlistSearch = async (wishlistId:number, searchTerm: string) => {
    const response = await makeRequest<ApiResponse<CardType[]>>({
        url: `/wishlists/${wishlistId}/search?name=${encodeURIComponent(searchTerm)}`,
        method: "GET",
    });

    return response.cards;
};

export const submitReport = async (values: {
  message: string;
  name: string;
  email: string;
}) => {
  const response = await fetch(`/report`, {
    body: JSON.stringify(values),
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN") ?? "",
    },
  });

  return response as Response;
};

export const addCardToWishlist = async (values: {
  card_id: number;
  wishlist_id: number;
}) => {
  const response = await makeRequest({
    url: `/wishlists/${values.wishlist_id}/card/${values.card_id}/add-card`,
    body: JSON.stringify({
      values,
    }),
  });

  return response;
};

export const createWishlist = async (
    name
) => {
    const response = await makeRequest({
        url: `/wishlists/create`,
        body: JSON.stringify({
            name
        })
    });

    return response;
};


export const importBulkCardListToWishlist = async (values: {
    wishlist_id: number;
    from: string;
    file: File;
}) => {
    const headers = new Headers();
    const formData = new FormData();
    formData.append("from", values.from);
    formData.append("file", values.file);

    headers.append("X-XSRF-TOKEN", Cookies.get("XSRF-TOKEN") ?? "");
    headers.append("Accept", "application/json");

    const response = await fetch(`/wishlists/${values.wishlist_id}/import-bulk`, {
        method: "POST",
        body: formData,
        headers: headers
    });

    return await response.json();
};

export const removeCardFromWishlist = async (cardId: number, wishlistId: number) => {
    return await makeRequest({
        url: `/wishlists/${wishlistId}/card/${cardId}/remove-card`,
    });
};

