import { SharedPageProps } from "@/common/types";
import { useContactSupport } from "@/contexts/contact-support-context";
import { Link, router, usePage } from "@inertiajs/react";
import { ListAlt, Store, WhatsApp } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BugReportIcon from "@mui/icons-material/BugReport";
import Launch from "@mui/icons-material/Launch";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import DynamicSidebarContainer from "./DynamicContainer";
import { StyledNavItem, StyledSupportButton } from "./style";

const MENU_ITEMS = [
  {
    title: "Card Search",
    icon: <ScreenSearchDesktopIcon />,
    path: "/",
    divider: true,
  },
  {
    title: "Vendors",
    icon: <Store />,
    path: "/vendors",
    isNew: false,
  },
  {
    title: "FAQ",
    icon: <SettingsSuggestIcon />,
    path: "/faq",
  },
  {
    title: "Changelogs",
    icon: <ManageHistoryIcon />,
    path: "/changelog",
  },
  // {
  //   title: "Analytics",
  //   icon: <ManageHistoryIcon />,
  //   path: "/changelog",
  //   divider: true,
  // },
  // {
  //   title: "Wishlists",
  //   icon: <Star />,
  //   path: "/wishlists",
  // },
  // {
  //   title: "Settings",
  //   icon: <ManageHistoryIcon />,
  //   path: "/changelog",
  // },
];

export const Sidebar = ({ mobileSidebarOpen, setMobileSidebarOpen }) => {
  const { url, props } = usePage<SharedPageProps>();
  const theme = useTheme();
  const { openSupportModal } = useContactSupport();
  const user = props.user?.data;
  const isMobile = useMediaQuery(
    `(max-width:${theme.breakpoints.values.sm}px)`,
  );

  router.on("before", () => {
    if (mobileSidebarOpen) {
      setMobileSidebarOpen(false);
    }
  });

  const SidebarContent = useCallback(() => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${theme.customVariables.headerHeight}px)`,
          justifyContent: "space-between",
        }}
      >
        <div>
          {MENU_ITEMS.map((item, index) => (
            <React.Fragment key={`${index}`}>
              <Link href={item.path}>
                <StyledNavItem active={item.path === url}>
                  {item.icon}
                  <Typography variant="body1">{item.title}</Typography>
                  {item.isNew && item.path !== url && (
                    <Box
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFF",
                        padding: "2px 8px",
                        borderRadius: "8px",
                        fontSize: "12px",
                        fontWeight: 600,
                        marginLeft: "16px",
                      }}
                    >
                      New
                    </Box>
                  )}
                </StyledNavItem>
              </Link>
              {item.divider && (
                <Divider
                  variant="middle"
                  sx={{
                    margin: "12px 16px",
                    display: "none",
                    [theme.breakpoints.up("sm")]: {
                      margin: "24px 16px",
                      display: "block",
                    },
                  }}
                />
              )}
            </React.Fragment>
          ))}
          {user ? (
            <Link href={"/wishlists"}>
              <StyledNavItem active={"/wishlists" === url}>
                <ListAlt />
                <Typography variant="body1">Wishlists</Typography>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFF",
                    padding: "2px 8px",
                    borderRadius: "8px",
                    fontSize: "12px",
                    fontWeight: 600,
                    marginLeft: "16px",
                  }}
                >
                  New
                </Box>
              </StyledNavItem>
            </Link>
          ) : (
            <Link href={"/login"}>
              <StyledNavItem active={"/wishlists" === url}>
                <ListAlt />
                <Typography variant="body1">Wishlists</Typography>
              </StyledNavItem>
            </Link>
          )}
        </div>

        <div>
          {isMobile && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "0px 20px",
                width: "100%",
                "& a": {
                  width: "100%",
                },
                "& svg": {
                  fontSize: "18px",
                },
              }}
            >
              <a
                href={"https://chat.whatsapp.com/ILIVNsecjJWC5pXd2bHVlB"}
                target="_blank"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    color: "#FFF",
                    border: "1px solid #FFF",
                    padding: "16px 25px",
                    borderRadius: "8px",
                    width: "100%",
                    height: "43px",
                  }}
                >
                  <WhatsApp sx={{ color: "#FFF", marginRight: "8px" }} />
                  Join our group
                  <Launch />
                </Box>
              </a>
            </Box>
          )}

          <StyledSupportButton>
            <Button
              variant="outlined"
              onClick={() => openSupportModal("general-feedback")}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "16px 25px",
                justifyContent: "space-around",

                height: "43px",
                flexGrow: 1,
              }}
            >
              <BugReportIcon />
              <Typography variant="body1">Report a bug</Typography>
              <AutoAwesomeIcon />
            </Button>
          </StyledSupportButton>
        </div>
      </Box>
    );
  }, [
    theme.breakpoints,
    url,
    openSupportModal,
    theme.customVariables.headerHeight,
  ]);

  return (
    <DynamicSidebarContainer
      mobileSidebarOpen={mobileSidebarOpen}
      toggleDrawer={() => setMobileSidebarOpen(!mobileSidebarOpen)}
    >
      <SidebarContent />
    </DynamicSidebarContainer>
  );
};
